import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { SUPPORTED_LANGUAGES } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../utils/configSlice";
import { useLocation } from "react-router-dom";


const Header = () => {
    const [showMenu , setShowMenu] = useState(true)
    const dispatch = useDispatch()
    const location = useLocation();
    const handleLanguageChange = (e) => {
        // console.log(e.target.value);
        dispatch(changeLanguage(e.target.value))
    }
    useEffect(()=>{
        if(location.pathname === '/privacy-terms-and-condition') {
            setShowMenu(false)
        }else {
            setShowMenu(true)
        }
    },[location])
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar_background">
  <div className="container-fluid">
    <a className="navbar-brand" href="https://yoothumbnail.com/">

    {/* <img src="https://cdn.pixabay.com/photo/2017/03/16/21/18/logo-2150297_640.png" alt="Logo" width="30" height="24" className="d-inline-block align-text-top"/> */}
     {/* <span className="logo ms-4">iyoutubethumnail</span>  */}
     <img src="images/logo.svg" alt="logo"/>
      </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse " id="navbarSupportedContent">

        {showMenu ? <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <Form.Select aria-label="Default select example" className="form-control select_border" onChange={handleLanguageChange}>
      {SUPPORTED_LANGUAGES.map(lang=> {
        return (
          <option key={lang.name} value= {lang.identifier} >{lang.name}</option>
        )
      }
    ) }
    </Form.Select>
      </ul> : '' }
      
    </div>
  </div>
</nav>
    </>
  )
}

export default Header