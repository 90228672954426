import React from 'react'
import lang from "../utils/languageConstant";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
const Footer = () => {
    const langKey = useSelector(store=> store.config.lang)  
    return (
        <>
            <div className="footer_bg col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                <div className="d-flex col-8 justify-content-between mobile_footer align-items-end">
                    <div className="ol-12 col-sm-6 col-md-6 col-lg-6">
                        {/* <p className="footer_text m-0 mt-5">{lang[langKey].websitename}</p> */}
                        <div className="footer_margin_top"><img src="images/logo.svg" alt="logo" /></div>
                        {/* <a href="mailto:someone@example.com" className="assestive pb-4 text-decoration-none">
          <img src="images/email.svg" className="me-3"/>{lang[langKey].webSiteEmail}</a> */}
                        <a href="mailto:someone@example.com" alt="mailto:someone@example.com" className="assestive pb-3 text-decoration-none">
                            <img src="images/email.svg" alt="email" className="me-3" /><span style={{ position: 'relative', top: '1px' }}>hello@yoothumbnail.com</span></a>
                        <div className="love_india">
                            <img src="images/love_india.svg" className="love_india" alt="made with love in india" />
                        </div>
                    </div>

                    <div>

                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-end footer_margin">
                        <a href="#" target="_blank" className="links" ><p className="extra_small">{lang[langKey].rights}</p> </a>
                        {/* <a href="#" target="_blank" className="links" ><p className="extra_small">All rights reserved © 2024 </p> </a> */}
                        {/* <a href="#" target="_blank" className="links"> <p className="extra_small">{lang[langKey].termAndCon}</p></a>  */}
                        <Link to={'/privacy-policy'} className="links"><p className="extra_small">{lang[langKey].privacy}</p></Link>
                    </div>

                </div>
            </div>
            <div className="gradient_strip"></div>
        </>
    )
}


export default Footer