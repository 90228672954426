import logo from './logo.svg';
import './App.css';
import PngToWebp from './Components/PngToWebp';
import ImageToWebp from './Components/ImageToWebp';
import YtDownload from './Components/YtDownload';
import { Provider } from 'react-redux'
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import appStore from './utils/appStore';
import LegalContent from './Components/LegalContent';
import  firebase  from 'firebase/compat/app'
import 'firebase/analytics'
import { firebaseConfig } from './utils/firebaseConfig'
function App() {
  firebase.initializeApp(firebaseConfig)
  return (
    <Router>
    <div className="App">
        
        {/* <PngToWebp /> */}
        {/* <ImageToWebp /> */}
  
    <Provider store={appStore}>
    <Routes>
        <Route exact path="/" element={<YtDownload />}>
        </Route>
        <Route exact path="/privacy-policy" element={<LegalContent />}>
      </Route>
        </Routes>

    </Provider>


    </div>
    </Router>

  );
}

export default App;
